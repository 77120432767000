<template>
    <div class="all" :style="{backgroundImage: 'url(' + bg + ')'}">
        
        <div class="all-title-bg" :style="{backgroundImage: 'url(' + bg_top + ')'}"></div>
        <div class="all-main">
            <el-card>
                <div v-html="contetn"></div>
            </el-card>
            
        </div>
        <div class="all-bottom-bg">
            <div class="all-bootom-txt">可以联系我们在下方留下你们的联系方式</div>
            <div class="all-bottom-content">我们的联系电话：{{phone_gs}}</div>
            <div class="all-flex">
                <div class="all-flex all-bottom-bottom">
                    <el-image class="email-image" :src="require('../../assets/image/about-email.png')"></el-image>
                    <div>邮箱：{{email}}</div>
                </div>
                <div class="all-flex">
                    <el-image class="wanzhi_image" :src="require('../../assets/image/baout-wangzhi.png')"></el-image>
                    <div>网址：{{web}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {about,getset} from '@/api/index'
export default {
    name:'About',
    data(){
        return{
            contetn:'',
            web:'',
            email:'',
            phone_gs:'',
            bg_top:'',
            bg:'',
        }
    },
    created(){
        this.getDeatail()
        getset({name:'about_bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
        getset({name:'about_top'}).then(res=>{this.bg_top = this.$store.state.baseUrl + res.data})
        getset({name:'web'}).then(res=>{this.web = res.data})
        getset({name:'email'}).then(res=>{this.email = res.data})
        getset({name:'phone_gs'}).then(res=>{this.phone_gs = res.data})
    },
    methods:{
        getDeatail(){
            about().then(res=>{
                this.contetn = res.data.about_content
            })
        }
    }
}
</script>
<style>
.el-card{
    background-color:rgba(255,255,255,0.7);
    border:none
}

</style>
<style scoped>
.zhanwei{
    height: 120px;
}
.all{
    background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.all-main{
    width: 80%;
    margin: auto;
    margin-top: -200px;
    margin-bottom: 100px;
}
.all-bottom-bg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../assets/image/about-bg.png');
    background-size: 100% 100%;
    color: #ffffff;
    font-size: 17px;
    width: 100%;
    height: 412px;
}
.all-flex{
    display: flex;
    align-items: center;
}
.all-bootom-txt{
    font-size: 17px;
    margin-bottom: 40px;
}
.all-bottom-content{
    font-size: 31px;
    color: #3781FF;
    margin-bottom: 35px;
}
.email-image{
    width: 18px;
    height: 14px;
    margin-right: 10px;
}
.wanzhi_image{
    height: 18px;
    width: 18px;
    margin-right: 10px;
}
.all-bottom-bottom{
    margin-right: 50px;
}
.all-title-bg{
    height: 1000px;
    background-size: 100% 100%;
}
</style>